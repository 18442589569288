import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getTranslationsFromServer } from './apiRequests';

const Languages = ['en', 'ru', 'he'];

const options = {
  order: [
    'path',
    'localStorage',
    'navigator',
    'querystring',
    'cookie',
    'htmlTag',
    'subdomain',
  ],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  cookieDomain: 'myDomain',
  htmlTag: document.documentElement,
  checkWhitelist: true,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'production' ? false : true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  });

/**
 * Loads additional translations from the server for specific pages.
 * @param {string} namespace - The translation namespace (e.g., 'landing')
 * @param {string} lng - Language code (e.g., 'en', 'ru')
 */
i18n.loadServerTranslations = async (namespace, lng = i18n.language) => {
  try {
    const { data } = await getTranslationsFromServer(namespace, lng);
    const cleanedData = data[namespace] || data;
    i18n.addResourceBundle(lng, namespace, cleanedData, true, true);
    // console.log(`Loaded translations for ${namespace}:`, data);
  } catch (error) {
    console.error(`Error loading translations for ${namespace}:`, error);
  }
};

export default i18n;
