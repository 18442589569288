import './App.css';
import { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from './components/SharedState';
import TagManager from 'react-gtm-module';
import { SEOdata } from './utils';
import ReactGA from 'react-ga4';
import { YMInitializer } from 'react-yandex-metrika';
import { HelmetProvider } from 'react-helmet-async';

const CookieConsentBanner = lazy(() =>
  import('./components/CookieConsentBanner/CookieConsentBanner')
);
const ScrollToTop = lazy(() =>
  import('./components/HelperComponents/ScrollToTop')
);
const Store = lazy(() => import('./components/SharedState/SearchState'));
const BackToTopButton = lazy(() =>
  import('./components/Layouts/BackToTopButton/BackToTopButton')
);
const ChatBox = lazy(() =>
  import('./components/Routes/Home/SecondSection/Components/ChatBox/ChatBox2')
);
// import QuickRentButton from './components/Routes/Home/SecondSection/Components/QuickRentButton';
const Routes = lazy(() => import('./components/Routes/Routes'));
const tagManagerArgs = {
  gtmId: 'GTM-M8FL5JP',
};
TagManager.initialize(tagManagerArgs);

const App = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_MEASURMENT_ID, { debug: true });
  }, []);

  window.dataLayer.push({
    event: 'pageview',
  });
  const {
    t,
    i18n: {
      languages: [lng],
    },
  } = useTranslation();
  const [currency, setCurrency] = useState({
    code: '',
    sign: '',
    icon: '',
  });
  let url = window.location.pathname;
  const urlLng = url.split('/')[1];
  if (urlLng !== lng) {
    url = `/${lng}` + url;
    window.location.replace(url);
  }
  if (window.location.pathname === '/') {
    window.location.replace(`${lng}`);
  }

  return (
    <HelmetProvider>
      {/* <SEOdata
        type='website'
        title={t('metatags.mainPage.titleWithoutLocation')}
        lng={lng}
        description={t('metatags.mainPage.descriptionWithoutLocation')}
      /> */}

      <div className="App" dir={lng === 'he' ? 'rtl' : 'ltr'}>
        {process.env.NODE_ENV !== 'development' && (
          <YMInitializer accounts={[67416511]} />
        )}
        <BrowserRouter basename={`/${lng}`}>
          <Suspense fallback={null}>
            <ScrollToTop />
          </Suspense>
          <Suspense fallback={null}>
            <CookieConsentBanner />
          </Suspense>
          <Suspense fallback={null}>
            <Store>
              <CurrencyContext.Provider value={[currency, setCurrency]}>
                <Suspense fallback={null}>
                  <Routes />
                </Suspense>
              </CurrencyContext.Provider>
              <Suspense fallback={null}>
                <BackToTopButton />
              </Suspense>
            </Store>
          </Suspense>
        </BrowserRouter>

        {/* <QuickRentButton /> */}
        <Suspense fallback={null}>
          <ChatBox lng={lng} />
        </Suspense>
      </div>
    </HelmetProvider>
  );
};

export default App;
