import { getFlagEmoji } from './components/Routes/SearchResults/OrderSummary/helpers/utils';
import { apiUrl } from './utils';
import axios from 'axios';

export const getReviews = () => {
  return axios.get(apiUrl('getreviews'));
};
export const getTranslationsFromServer = (namespace, lng) => {
  return axios.get(apiUrl(`translations/${namespace}/${lng}`));
};
export const createLead = (leadData) => {
  const url = apiUrl(`leads/createleadstep3`);
  return axios.post(url, leadData);
};
/**
 *
 * @param {*} locationCode
 * @param {*} country code
 * @returns {Promise} {url: String} in case of success. Error {url: defaultImage}
 */
export const getBackgroundImage = (locationCode, country) => {
  const url = apiUrl(
    `getbackgroundimage?${locationCode ? `locationCode=${locationCode}` : ''}${
      locationCode ? '&' : ''
    }countryCode=${country}`
  );
  return axios.get(url);
};

/**
 * Find locations by term
 * @param {String} term
 * @param {String} lng
 * @returns {Array} locations
 */
export const searchLocationsByTerm = (term, lng, country, locationCode) => {
  const searchTerm = term ? `term=${term}` : '';
  const searchCountry = country ? `${term ? '&' : ''}&country=${country}` : '';
  return axios.get(
    apiUrl(
      `searchlocation?${searchTerm}${
        term ? '&' : ''
      }lng=${lng}${searchCountry}${
        locationCode ? `&locationCode=${locationCode}` : ''
      }`
    )
  );
};

export const getPrivacyPolicyPage = (lng) => {
  return axios.get(apiUrl(`getprivacypolicy?lng=${lng}`));
};

export const getCookiePolicyPage = (lng) => {
  return axios.get(apiUrl(`getcookiepolicypage?lng=${lng}`));
};

export const submitRentApartmentForm = (values) => {
  return axios.post(apiUrl(`submitrentapartmentform`), { values });
};

export const getAllCommonArticles = (lng, country) => {
  return axios.get(
    apiUrl(
      `staticpage/getallcommonarticles?lng=${lng}${
        country ? `&country=${country}` : ''
      }`
    )
  );
};

export const getLocationPage = (country, location, lng, url) => {
  return axios.get(
    apiUrl(
      `staticpage/aboutlocationpage?country=${country}&lng=${lng}${
        location ? `&location=${location}` : ''
      }${url ? `&url=${url}` : ''}`
    )
  );
};
export const getCommonArticlePage = (lng, url) => {
  return axios.get(apiUrl(`staticpage/commonarticle?lng=${lng}&url=${url}`));
};
export const getAllCountries = (lng) => {
  return axios.get(
    apiUrl(`locations/getallcountries?${lng ? `&lng=${lng}` : ''}`)
  );
};
export const getAllAvailableCountries = (lng, thumbnail) => {
  return axios.get(
    apiUrl(
      `locations/getallavailablecountries?lng=${lng}&thumbnail=${
        thumbnail || false
      }`
    )
  );
};
export const getAllCountries2 = (lng) => {
  return axios.get(apiUrl(`locations/getallcountries2?lng=${lng}`));
};

export const getCountryByPhoneCode = (phonecode) => {
  return axios.get(
    apiUrl(`countries/getcountrybyphonecode?phonecode=${phonecode}`)
  );
};

export const getcountrypopularlocations = (countrycode, excludecode, lng) => {
  return axios.get(
    apiUrl(
      `locations/getcountrypopularlocations?countrycode=${countrycode}&lng=${lng}${
        excludecode ? `&excludecode=${excludecode}` : ''
      }`
    )
  );
};

export const getInitialPhoneCode = () => {
  return new Promise((resolve, reject) => {
    axios
      .get('https://ipapi.co/json')
      .then(({ data }) => {
        const { country, country_calling_code } = data;
        const code = country_calling_code.replace('+', '');
        const flag = getFlagEmoji(country);
        return resolve({ code, flag });
      })
      .catch((err) => {
        console.log(err);
        return reject({ code: '', flag: '' });
      });
  });
};
export const findLocationByLocationCode = (LocationCode) => {
  return axios.get(
    apiUrl(`locations/findlocationbylocationcode?locationcode=${LocationCode}`)
  );
};

export const getRelatedPosts = (country, lng) => {
  return axios.get(
    apiUrl(`articles/relatedposts?country=${country}&lng=${lng}`)
  );
};
