import React from 'react';
import { Helmet } from 'react-helmet-async';
//logo url https://www.we4rent.com/images/logo.png
/**
 *
 * @param {*} param0 language | canonical url | type | title | description | image url
 */
export const SEOdata = ({
  lang,
  canonicalUrl,
  type,
  title,
  description,
  image,
  noIndex,
  keywords,
}) => {
  // React.useEffect(() => {
  //   console.log('TITLE:', title);
  //   console.log('ACTUAL TITLE: ', document.title);

  //   console.log('DESCRIPTION: ', description);
  //   console.log(
  //     'ACTUAL DESCRIPTION: ',
  //     document.querySelector('meta[name="description"]')?.content
  //   );
  //   console.log(
  //     'OG:TITLE',
  //     document.querySelector('meta[property="og:title"]')?.content
  //   );
  //   console.log(
  //     'OG:DESCRIPTION',
  //     document.querySelector('meta[property="og:description"]')?.content
  //   );
  // }, [title, description]);
  // React.useEffect(() => {
  //   if (title && document.title !== title) {
  //     document.title = title;
  //     document
  //       .querySelector('meta[property="og:title"]')
  //       ?.setAttribute('content', title);
  //   }
  // }, [title]);
  // React.useEffect(() => {
  //   if (description) {
  //     const metaDescription = document.querySelector(
  //       'meta[name="description"]'
  //     );
  //     if (metaDescription) {
  //       metaDescription.setAttribute('content', description);
  //     } else {
  //       const meta = document.createElement('meta');
  //       meta.name = 'description';
  //       meta.content = description;
  //       document.head.appendChild(meta);
  //     }
  //   }
  // }, [description]);
  React.useEffect(() => {
    const updateMetaTag = (selector, attr, value, isName = false) => {
      if (!value) return;
      let tag = document.querySelector(selector);
      if (tag) {
        tag.setAttribute(attr, value);
      } else {
        tag = document.createElement('meta');
        if (isName) {
          tag.setAttribute(
            'name',
            selector.replace('meta[name="', '').replace('"]', '')
          );
        } else {
          tag.setAttribute(
            'property',
            selector.replace('meta[property="', '').replace('"]', '')
          );
        }
        tag.setAttribute(attr, value);
        document.head.appendChild(tag);
      }
    };

    if (title) {
      document.title = title;
      updateMetaTag('meta[property="og:title"]', 'content', title);
    }

    if (description) {
      updateMetaTag('meta[name="description"]', 'content', description, true); 
      updateMetaTag('meta[property="og:description"]', 'content', description);
    }

    if (image) {
      updateMetaTag('meta[property="og:image"]', 'content', image);
    }

    if (canonicalUrl) {
      updateMetaTag('meta[property="og:url"]', 'content', canonicalUrl);
    }

    if (type) {
      updateMetaTag('meta[property="og:type"]', 'content', type);
    }
  }, [title, description, image, canonicalUrl, type]);
  return (
    <Helmet>
      <title>{title}</title>
      {lang && <html lang={lang} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta
        name="description"
        content={description ? description.trim() : ''}
      />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      {description && (
        <meta property="og:description" content={description.trim()} />
      )}
      <meta
        property="og:image"
        content={image || 'https://www.we4rent.com/images/logo.png'}
      />
      <meta property="og:type" content={type} />
      <meta property="fb:app_id" content="108281041034261" />
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};
